 /* Navbar Container */
 .custom-navbar {
  background-color: #2c3e50; /* Darker background for elegance */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  padding: 0.8rem 1.5rem; /* Add padding for breathing space */
}

/* Brand Styling */
.navbar-brand-custom {
  color: #ffffff !important; /* White color with !important to override */
  font-weight: 600;
  font-size: 1.3rem;
  letter-spacing: 0.05rem;

}

.navbar-brand-custom:hover {
  color: #f39c12; /* Highlight color on hover */
}

/* Centered Nav Links */
.custom-nav-links .nav-link {
  color: white;
  font-size: 1.1rem;
  font-weight: 350;
  margin: 0 1rem;
  transition: color 0.3s ease;
  display: flex;

}

.custom-nav-links .nav-link:hover {
  color: #f39c12;
  text-decoration: none;
}

/* Right-Aligned Auth Links */
.custom-nav-auth .nav-link {
  color: #bdc3c7;
  font-size: 1rem;
  font-weight: 500;
  margin: 0 0.5rem;
  transition: color 0.3s ease;
}

.custom-nav-auth .nav-link:hover {
  color: #f39c12;
  text-decoration: none;
}

/* Mobile View Adjustments */
.navbar-toggler {
  border: none; /* Remove the default border if needed */
}

.navbar-toggler-icon {
  width: 24px;
  height: 24px;
  background-color: transparent; /* Ensure the background is clear */
  mask: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3E%3Cpath stroke='white' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E") no-repeat center;
  -webkit-mask: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3E%3Cpath stroke='white' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E") no-repeat center;
  background-color: white; /* Color of the icon */
}

/* Adjust for Vertically Centered Links */
.custom-nav-links,
.custom-nav-auth {
  display: flex;
  align-items: center;
}

/* Modal Styling */
.custom-modal {
  text-align: center;
  z-index: 1050; /* Ensure it's above the backdrop */
}

/* Modal Header Styling */
.custom-modal-header {
  border-bottom: none;
  font-size: 1.5rem;
  font-weight: bold;
}

/* Button Styling */
/* Remove underlines from sign-in buttons */
.custom-auth-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 12px;
  margin-bottom: 10px;
  font-size: 1rem;
  font-weight: 500;
  color: #333;
  background-color: white;
  border: 2px solid #ccc;
  border-radius: 30px;
  text-align: center;
  text-decoration: none; /* Remove underline */
  transition: border-color 0.3s, color 0.3s;
}

.custom-auth-button:hover {
  border-color: #007bff; /* Highlight border on hover */
  color: #007bff; /* Highlight text on hover */
  text-decoration: none; /* Ensure underline is still removed on hover */
}


/* Icon Styling in Buttons */
.auth-icon {
  margin-right: 10px; /* Space between the icon and text */
  font-size: 1.2rem; /* Adjust icon size */
}

/* Close Button Styling */
.custom-close-button {
  font-size: 1.5rem;
  position: absolute;
  top: 10px;
  right: 20px;
  border: none;
  background: none;
  cursor: pointer;
}

/* Fullscreen Blur Effect for Modal */
.modal-backdrop-blur {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(10px); /* Apply full-screen blur */
  background-color: rgba(0, 0, 0, 0.3); /* Dark overlay for dim effect */
  z-index: 1040; /* Ensure it stays behind the modal */
}

/* Stronger Full Background Blur Effect */
.modal-open .modal-backdrop {
  backdrop-filter: blur(30px); /* Significantly higher blur intensity */
  background-color: rgba(0, 0, 0, 0.5); /* Slightly darker overlay for better contrast */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1040;
}

.navbar-collapse.show {
    display: block !important; /* Ensure it shows when expanded */
}

.navbar-collapse {
    display: none !important; /* Ensure it collapses by default */
}
