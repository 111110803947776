.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.testimonials-container {
  background-color: #f8f9fa;
  padding: 50px 0;
}

.testimonials-container h2 {
  text-align: center;
  margin-bottom: 30px;
}

.testimonials-container .card {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: none;
}

.testimonial-item {
  margin-top: 30px;
}

.testimonial-card {
  border: none;
  background-color: #f8f9fa; /* Light background */
  transition: transform 0.3s ease;
}

.testimonial-card:hover {
  transform: scale(1.05); /* Slight zoom on hover */
}

.card-title {
  font-size: 1.5rem;
}

.card-text {
  font-size: 1.1rem;
}

.container {
  margin-bottom: 50px;
}

h2 {
  font-family: 'Helvetica Neue', sans-serif;
  letter-spacing: 1px;
}

.blogs-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.blogs-heading {
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 2rem;
  text-align: left;
}

.blog-card-wrapper {
  display: flex;
  justify-content: space-between;
}

.blog-card {
  display: flex;
  width: 100%;
  padding: 20px;
  border: 1px solid #eaeaea;
  border-radius: 8px;
  background-color: #fff;
  transition: box-shadow 0.3s ease;
}

.blog-card:hover {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.blog-image-wrapper {
  width: 150px;
  height: 150px;
  overflow: hidden;
  border-radius: 8px;
  margin-right: 20px;
}

.blog-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}

.blog-details {
  flex: 1;
}

.blog-title {
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 10px;
  color: #333;
}

.blog-summary {
  font-size: 1rem;
  margin-bottom: 10px;
  color: #555;
}

.blog-meta {
  font-size: 0.9rem;
  color: #777;
  margin-bottom: 15px;
}

.blog-read-more {
  font-weight: 600;
  color: #007bff;
  text-decoration: none;
}

.blog-read-more:hover {
  text-decoration: underline;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



