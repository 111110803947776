/* Hero Section Styling */
.hero-section {
    background-image: url('/public/interior2.jpg'); /* Ensure your path is correct */
    background-size: cover;
    background-position: center;
    min-height: 100vh; /* Full viewport height */
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    color: #fff;
    text-align: center;
    margin: 0; /* Remove any margins */
    padding: 0; /* Remove any padding */
}

/* Section Wrapper - General Styling */
.section-wrapper {
    padding-top: 0; /* Remove top padding */
    margin-top: -5px; /* Negative margin to eliminate extra space */
    background-color: #f8f9fa;
}

/* Additional Hero Content Adjustments */
.hero-content {
    position: relative;
    z-index: 2;
    text-align: center;
    padding: 20px;
}

.hero-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6); /* Adjust the opacity for readability */
    z-index: 1;
}

.hero-name {
    font-size: 4rem;
    font-weight: bold;
    margin: 0;
    color: #fff;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.6);
}

.hero-title {
    font-size: 2rem;
    margin-top: 10px;
    font-weight: 300;
    color: #f1f1f1;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.6);
}

.hero-buttons {
    margin-top: 20px;
}

.hero-button {
    font-size: 1.2rem;
    padding: 10px 20px;
    margin: 0 10px;
    border: none;
    border-radius: 4px;
    transition: background-color 0.3s;
}

.hero-button:hover {
    background-color: #0056b3;
    color: #fff;
}

/* Profile Photo Styling */
.profile-photo {
    position: absolute;
    top: 40%;
    left: 10%;
    transform: translate(-50%, -50%);
    width: 200px;
    height: auto;
    overflow: hidden;
}

.profile-photo img {
    width: 100%;
    border-radius: 50%;
}

/* General Section Wrapper Styling */
.section-wrapper {
    padding: 40px 0; /* Adjusted padding for even spacing */
    background-color: #fff; /* White background for clean look */
    max-width: 1200px;
    margin: 0 auto; /* Center content */
}

/* Section Title Enhancements */
.section-title {
    font-size: 2.8rem;
    font-weight: bold;
    text-align: center;
    background-color: #000;
    color: #fff;
    padding: 15px 40px; /* Adjusted padding for balanced look */
    display: inline-block; /* Center title */
    border-radius: 5px; /* Rounded corners */
    margin-bottom: 40px; /* Space below title */
    position: relative;
}

/* Downward Arrow Styling */
.section-title::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    border-width: 10px;
    border-style: solid;
    border-color: #000 transparent transparent transparent;
}

/* Content Layout Using Flexbox */
.section-content {
    display: flex;
    align-items: center;
    justify-content: space-around; /* Space evenly */
    gap: 20px; /* Reduced gap for cleaner layout */
    padding: 0 20px; /* Side padding for content */
}

.section-text {
    flex: 1;
    text-align: left; /* Align text to the left */
    max-width: 500px; /* Limit text width for readability */
}

.section-text h3 {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 10px; /* Reduced space below heading */
}

.section-text p {
    font-size: 1rem;
    color: #555; /* Softer text color */
    line-height: 1.6;
    margin-bottom: 20px; /* Space below paragraph */
}

/* Button Styling */
.section-button {
    background-color: #4a90e2; /* Light blue color */
    color: #fff;
    font-size: 1rem;
    font-weight: bold;
    padding: 10px 20px; /* Adjusted button padding */
    border-radius: 5px;
    border: none;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 5px; /* Space between text and icon */
    transition: background-color 0.3s;
}

.section-button:hover {
    background-color: #357ab8; /* Darker blue on hover */
}

/* Image Adjustments */
.section-image {
    flex: 1;
    max-width: 450px; /* Limit image width */
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

/* Responsive Adjustments */
@media (max-width: 992px) {
    .section-content {
        flex-direction: column; /* Stack vertically */
        text-align: center;
    }

    .section-text {
        text-align: center; /* Center text for smaller screens */
        max-width: 100%; /* Full width for text */
    }

    .section-image {
        max-width: 80%; /* Reduce image width */
        margin-top: 20px; /* Space above image */
    }
}

/* Testimonials Section Styling */
.testimonials-section {
    padding: 60px 0;
    background-color: #f9f9f9;
    text-align: center;
    position: relative;
}

/* Testimonials Section Title Styling */
.testimonials-title {
    font-size: 2.8rem;
    font-weight: bold;
    text-align: center;
    background-color: #000;
    color: #fff;
    padding: 10px 30px; /* Adjusted padding to match the style */
    margin: 30px auto; /* Center the title and add space above */
    position: relative;
    display: inline-block; /* Make the title fit the content */
}

/* Downward Arrow Below Testimonials Title */
.testimonials-title::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    border-width: 10px; /* Arrow size */
    border-style: solid;
    border-color: #000 transparent transparent transparent;
}



.testimonials-carousel {
    display: flex;
    justify-content: space-around;
    align-items: stretch;
    max-width: 1200px;
    margin: 0 auto;
    transition: opacity 0.3s ease-in-out;
}

.testimonial-card {
    flex: 1;
    max-width: 30%;
    min-width: 300px;
    margin: 0 10px;
    background-color: #fff;
    border: none;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    position: relative;
}

.testimonial-feedback {
    font-style: italic;
    color: #555;
}

.testimonial-name {
    font-weight: bold;
    font-size: 1.2rem;
    margin-top: 15px;
    color: #333;
}

.testimonial-role {
    font-size: 1rem;
    color: #777;
}

/* Fade-in and fade-out effects */
.fade-in {
    opacity: 1;
    animation: fadeIn 0.5s forwards;
}

.fade-out {
    opacity: 0;
    animation: fadeOut 0.5s forwards;
}

@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

@keyframes fadeOut {
    from { opacity: 1; }
    to { opacity: 0; }
}

/* Carousel Controls */
.carousel-controls {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.carousel-control {
    background: #007bff;
    border: none;
    color: #fff;
    font-size: 1.5rem;
    cursor: pointer;
    padding: 10px;
    border-radius: 50%;
    transition: background-color 0.3s;
}

.carousel-control:hover {
    background-color: #0056b3;
}

@media (max-width: 768px) {
    .testimonials-carousel {
        flex-direction: column;
        align-items: center;
    }

    .testimonial-card {
        max-width: 90%;
        margin: 10px 0;
    }
}

/* Contact Section Styling */
.contact-section {
    background-image: url('/public/interior2.jpg'); /* Adjust path as necessary */
    background-size: cover;
    background-position: center;
    padding: 100px 0;
    color: #fff;
    text-align: center;
    position: relative;
    margin-bottom: 2rem;
}

.contact-content {
    position: relative;
    z-index: 2;
}

.contact-user-icon {
    font-size: 5rem;
    margin-bottom: 20px;
    color: #fff;
}

.contact-phone {
    font-size: 3.5rem;
    font-weight: bold;
    margin: 10px 0;
}

.contact-name {
    font-size: 2rem;
    font-weight: bold;
    margin-top: 10px;
}

.contact-details {
    font-size: 1.2rem;
    margin-bottom: 30px;
}

.social-icons {
    margin: 20px 0;
}

.social-icons > * {
    font-size: 1.8rem;
    margin: 0 15px;
    color: #fff;
    cursor: pointer;
    transition: color 0.3s;
}

.social-icons > *:hover {
    color: #ccc;
}

.contact-button {
    background-color: #000 !important;
    color: #fff !important;
    font-weight: bold !important;
    font-size: 1rem !important;
    padding: 10px 20px !important;
    border: none !important;
    border-radius: 4px !important;
    text-transform: uppercase !important;
    cursor: pointer;
    transition: transform 0.2s ease !important;
}

.contact-button:hover {
    transform: scale(1.05) !important;
}

.contact-section::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 1;
}

@media (max-width: 768px) {
    .section-wrapper {
        padding: 80px 0;
    }
    .section-title {
        font-size: 2rem;
    }
    .section-content {
        padding: 30px;
    }
}
