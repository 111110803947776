/* Selling Page Styling */

.selling-page {
    padding: 2rem 0;
}

.selling-content {
    max-width: 900px;
    margin: 0 auto;
    padding: 1rem;
}

/* Hero section styling */
.hero-section {
    background-image: url('/public/interior2.jpg'); /* Update this to the correct path */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 60vh;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    color: #fff;
}

.hero-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6); /* Dark overlay for readability */
    z-index: 1;
}

.hero-content {
    position: relative;
    z-index: 2;
    text-align: center;
    color: #fff;
}

.hero-title {
    font-size: 2.5rem;
    font-weight: bold;
    margin: 0;
}

.hero-subtitle {
    font-size: 1.2rem;
    margin-top: 0.5rem;
}

/* Main form container styling */
.main-content {
    background-color: #f8f9fa; /* Light background for the form section */
    padding: 2rem 1rem;
}

/* Form wrapper */
.evaluation-form {
    background-color: #ffffff;
    padding: 2.5rem;
    border: 1px solid #e3e3e3;
    border-radius: 8px;
    max-width: 800px;
    margin: 2rem auto;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.form-info {
    font-size: 1.1rem;
    color: #333;
    margin-bottom: 2rem;
    text-align: center;
}

.evaluation-form h3 {
    font-size: 1.5rem;
    font-weight: bold;
    color: #333;
    margin-top: 2rem;
    margin-bottom: 1.5rem;
    text-align: left;
}

/* Label styling */
.evaluation-form .form-label {
    font-weight: 600;
    color: #333;
    text-align: left !important;
    display: block;
    margin-bottom: 0.5rem;
}

/* Input fields */
.evaluation-form .form-control,
.evaluation-form .form-select {
    padding: 0.75rem;
    margin-bottom: 1.5rem;
    font-size: 1rem;
}

.contact-method-options {
    display: flex;
    gap: 1rem;
    margin-top: 0.5rem;
}

/* Submit button styling */
.submit-button {
    margin-top: 2rem;
    background-color: #007bff;
    color: #fff;
    font-weight: bold;
    padding: 0.8rem 1.5rem;
    border-radius: 5px;
    width: 100%;
    font-size: 1rem;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .evaluation-form {
        padding: 1.5rem;
    }

    .evaluation-form h3 {
        font-size: 1.15rem;
    }

    .evaluation-form .form-label {
        font-size: 0.95rem;
    }

    .evaluation-form .form-control,
    .evaluation-form .form-select {
        font-size: 0.9rem;
        padding: 0.65rem;
    }

    .submit-button {
        padding: 0.7rem;
        font-size: 0.9rem;
    }
}

@media (max-width: 480px) {
    .evaluation-form {
        padding: 1rem;
    }

    .form-info {
        font-size: 0.9rem;
    }

    .evaluation-form h3 {
        font-size: 1.2rem;
        margin-top: 1rem;
        margin-bottom: 0.8rem;
    }

    .evaluation-form .form-control,
    .evaluation-form .form-select {
        padding: 0.5rem;
        font-size: 0.85rem;
    }

    .submit-button {
        padding: 0.6rem;
        font-size: 0.85rem;
    }
}
